<template>
    <div class="current">
        <div v-if="msgs.length > 0">
            <ul class="msgList">
                <li v-for="(item,index) in msgs" :key="index" :class="[item.readFlag == 0 ? 'newMsg' : '']" @click="showDetail(item)">
                    <span class="time">{{$m_formulateTime(item.deliveryTime)}}</span>
                    <!--  <span class="time">{{$moment(item.deliveryTime).fromNow()}}</span> -->
                    <span class="text">
                        <div class="mxscoll" v-html="item.msgBody"></div>
                    </span>
                    <span class="more" @click.stop="moreClick(item)">
                        <i class="iconfont icon-more"></i>
                    </span>
                </li>
            </ul>
        </div>
        <!-- 暂无消息 -->
        <nodata v-else :title="$t('mx_mobile.workbench.1665800402989')" img="noData"></nodata>
        <!-- 查看更多 -->
        <div class="more" @click="$router.push({path: '/workbench/schedule',query: {id: 0}})" v-if="msgs.length > 4">{{$t('mx_mobile.Client.1627017309696')}}</div>
        <section class="isLoading" v-if="isLoading">
            <loading style="margin-top:70px;" />
        </section>

        <!-- 延迟弹窗 -->
        <dialog-delay ref="dialogDelay" @success="getList()" />
        <!-- 弹出详情 -->
        <van-popup v-model="isShowItem" class="showItemBody">{{showItemTxt}}</van-popup>
    </div>
</template>

<script>
import dialogDelay from '../dialogDelay.vue'
export default {
    name: 'current',
    data() {
        return {
            isLoading: false,
            isShowItem: false,
            showItemTxt: '',
            msgs: [],
            pageN: 1, // 当前第几期
            // flag: true,
            pageSize: 5, // 每页多少条
            staffId: this.$router.currentRoute.query.subordKey || '' // 部门员工接口key
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        showDetail(item) {
            // this.isShowItem = tr
            this.showItemTxt = item.msgBody
            this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.message_put, {
                msgId: item.msgId,
                type: 'read',
                sourceId: 'mobile'
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.getList() // 重新加载一下数据
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
            // 处理跳转
            this.msgJump(item)
        },
        // 打开更多
        async moreClick(item) {
            try {
                let res = await this.Global.utils.actionSheet.open([
                    {
                        name: this.$t('mx_mobile.workbench.1584934177147'), // '延迟'
                        index: 0
                    },
                    {
                        name: this.$t('mx_mobile.workbench.1584934407018'), // '关闭'
                        index: 1
                    }
                ])
                if (!res) { return }
                switch (res.index) {
                    case 0:
                        this.$refs.dialogDelay.open(item) // 打开延迟
                        break
                    case 1:
                        this.closeMsg(item)
                        break
                    case -1:
                        break
                    default:
                        console.log(res)
                        break
                }
            } catch (e) {
                console.log(e)
            }
        },
        // 关闭消息
        closeMsg(item) {
            this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.message_put, {
                msgId: item.msgId,
                type: 'delete',
                sourceId: 'mobile'
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(this.$t('mx_mobile.workbench.1584934471910')) // '关闭成功'
                    this.getList() // 重新加载一下数据
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取列表
        getList() {
            this.isLoading = true
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            const url = this.Global.config.apiBaseURL + this.Global.api.SystemSet.scheduleremind.messageGet
            const params = {
                type: 'unHandleList',
                pageN: this.pageN,
                pageSize: this.pageSize,
                sort: 'deliveryTime',
                order: 'desc',
                targetCtId: this.staffId
            }
            this.axios.get(url, { params })
                .then(res => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        this.isLoading = false
                        this.msgs = res.data.data.messageRecordList
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    components: {
        'dialog-delay': dialogDelay
    },
    watch: {
        $route(to, from) {
            let que = to.query
            if (que && que.subordKey) {
                if (que.flag == 'isPers') {
                    this.getList()
                }
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.current {
    // border:1px solid red;
    position: relative;
    .isLoading {
        // border:1px solid red;
        background: #fff;
        position: absolute;
        left: 0;
        top: 2px;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .more {
        text-align: center;
        height: 40px;
        line-height: 30px;
    }
    .mxscoll {
        overflow: auto;
        width: 100%;
    }
}
</style>
