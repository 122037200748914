<template>
    <div class="DepartRank moduleBox">
        <div class="moduleBox_header">
            <!-- 部门内排行 -->
            <span class="name">{{itemData.statName}}</span>

            <div class="select">
                <!-- 部门下拉，下拉数据不只一个时出现 -->
                <!-- <span class="btns" v-if="actions2.length > 1" @click="show2 = true" style="right:80px;"> -->
                <span class="btns" v-if="actions2.length > 0" @click="deptOpen()" style="margin-right:5px;">
                    <span class="name">{{selectItem2.name}}</span>
                    <i class="iconfont icon-page-down"></i>
                </span>

                <!-- 区间下拉 -->
                <span v-if="flag == 'isPers'" class="btns" @click="dateOpen()">
                    <span class="name">{{selectItem.name}}</span>
                    <i class="iconfont icon-page-down"></i>
                </span>
            </div>
        </div>
        <section class="workBody">
            <ul class="tabs van-hairline--bottom">
                <li v-for="(item,index) in tabs" :key="index" :class="[tabActive == index ? 'tabActive' : '']" @touchend.stop="tabActive=index; " @click="DepartRankClick">{{item}}</li>
            </ul>
            <div v-if="flag == 'isPers'" class="rankDescript">
                <table>
                    <tbody>
                        <tr>
                            <td width="30%"><img :src="accountAvatar"></td>
                            <td width="40%" align="center">
                                <div @click="toClient()">{{count}}</div>
                                <!-- 您本月新增客户总数 -->
                                <span>{{$t('mx_mobile.workbench.1665738957575')}}</span>
                                <!-- <span>您{{selectItem.name}}{{tabs[tabActive]}}总数</span> -->
                                <span>{{$t('mx_mobile.workbench.1584938937769', [selectItem.name, tabs[tabActive]])}}</span>
                            </td>
                            <td width="30%" align="center">
                                <div>{{rank}}</div>
                                <!-- 排名 -->
                                <span>{{$t('mx_mobile.workbench.1584938594934')}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="rankList" v-if="rankInfoList.length > 0">
                <table>
                    <tbody>
                        <tr v-for="(item, index) in rankInfoList" :key="index">
                            <td class="index">{{index + 1}}</td>
                            <td>
                                <img :src="getUserPicUrl(item.avatar)">
                            </td>
                            <td>{{item.nickName}}</td>
                            <td width="30%" align="center" @click="toClient(item.ctId)">{{item.count}}</td>
                            <!-- <td width="30%" align="center">{{item.upOrDown}}</td> -->
                            <td width="30%" align="center">
                                <span class="icon">
                                    <i v-if="item.upOrDown==1" class="iconfont icon-arrow-up-solid"></i>
                                    <i v-if="item.upOrDown==0" class="iconfont icon-minus"></i>
                                    <i v-if="item.upOrDown==-1" class="iconfont icon-arrow-down-solid"></i>
                                    <i v-if="item.upOrDown==-2" class="iconfont icon-minus"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <nodata v-else></nodata>
        </section>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'DepartRank',
    props: ['itemData', 'flag'],
    data() {
        return {
            // show: true,
            show: false,
            actions: [],
            selectItem: { // 默认时间段
                index: '3',
                name: this.$t('mx_mobile.workbench.1584939196431') // '本月'
            },

            // show2: true,
            show2: false,
            actions2: [],
            selectItem2: {},

            tabs: [
                this.$t('mx_mobile.workbench.1584939336363'), // '新增'
                this.$t('mx_mobile.workbench.1584939380583'), // '跟进客户'
                this.$t('mx_mobile.workbench.1584939397720'), // '跟进数'
                this.$t('mx_mobile.workbench.1584939411403') // '成交'
            ],
            tabActive: 0,

            size: 10,
            count: 0,
            rank: 0,
            rankInfoList: [],
            accountAvatar: '',
            // 接口需要的key
            staffId: this.$router.currentRoute.query.subordKey || ''
        }
    },
    created() {
        // 时间区间下拉
        if (this.itemData.statInterval) {
            this.actions = []
            this.itemData.statInterval.forEach(item => {
                this.actions.push({
                    index: item.intervalCode,
                    name: item.intervalName
                })
            })
        }
        // 部门下拉
        if (this.itemData.depts) {
            let deptArr = this.itemData.depts
            if (deptArr.length === 0) { return }
            this.actions2 = []
            deptArr.forEach(item => {
                this.actions2.push({
                    index: item.dkey,
                    name: item.deptName
                })
            })
            this.selectItem2 = this.actions2[0] // 默认取第一项
            // 部门ID确定后，再加载接口
        }
        if (this.flag == 'isPers') {
            this.getDepartRankData()
        } else {
            this.daptDepartRankData()
        }
        this.getCompanysetting() // 获取企业配置信息
    },
    mounted() { },
    computed: {
        ...mapGetters(['companie'])
    },
    methods: {
        DepartRankClick() {
            if (this.flag == 'isPers') {
                this.getDepartRankData()
            } else {
                this.daptDepartRankData()
            }
        },
        toClient(ctId) { // 跳转客户
            let modelCode = ''
            let timeType = ''
            if (this.tabActive == 1 || this.tabActive == 2) {
                modelCode = 'BF001'
                if (this.tabActive == 2) {
                    timeType = '3'
                }
            } else if (this.tabActive == 3) {
                modelCode = 'BF004'
            } else if (this.tabActive == 4) {
                return false
            }

            let data = {
                modelCode: modelCode,
                interval: this.selectItem.index,
                argument: {}
            }
            if (ctId) {
                data.argument.ownerCtId = ctId
            } else {
                data.argument.ownerCtId = this.companie.ctId
            }
            if (timeType) {
                data.timeType = timeType
            }
            this.toClient_g(data)
        },
        // 获取企业配置信息
        getCompanysetting() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.account_get, { params: {} }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let size = '64x64'
                    if (res.data.data.avatar && res.data.data.avatar !== '') {
                        this.accountAvatar = this.getGlobalImgSrc(res.data.data.avatar, size)
                    } else {
                        this.accountAvatar = this.getGlobalImgSrc('3,107d091f7e2d', size)// 默认
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }, res => {
                this.$toast.fail(res.data.msg)
            })
        },
        // 时间区间选择
        async dateOpen() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.actions)
                if (!res) { return }
                this.selectItem = res
                // this.getDepartRankData() // 加载选择的数据
                if (this.flag == 'isPers') {
                    this.getDepartRankData()
                } else {
                    this.daptDepartRankData()
                }
            } catch (e) {
                console.log(e)
            }
        },
        // 部门选择
        async deptOpen() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.actions2)
                if (!res) { return }
                this.selectItem2 = res

                // this.getDepartRankData() // 加载选择的数据
                if (this.flag == 'isPers') {
                    this.getDepartRankData()
                } else {
                    this.daptDepartRankData()
                }
            } catch (e) {
                console.log(e)
            }
        },
        // 拼接头像
        getUserPicUrl(avatar) {
            let size = '28x28'
            return avatar && avatar !== '' ? this.getGlobalImgSrc(avatar, size) : this.getGlobalImgSrc('3,107d091f7e2d', size)
        },
        // 下属
        getDepartRankData() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_rank_get, {
                params: {
                    fieldId: this.tabActive + 1,
                    size: this.size,
                    dkey: this.selectItem2.index,

                    statId: this.itemData.statId,
                    interval: this.selectItem.index,

                    staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.count = res.data.data.count
                    this.rank = res.data.data.rank
                    this.rankInfoList = res.data.data.rankInfoList
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 部门
        daptDepartRankData() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_dept_rank_get, {
                params: {
                    fieldId: this.tabActive + 1,
                    size: this.size,
                    // dkey: this.selectItem2.index,
                    dkey: this.staffId,
                    interval: this.selectItem.index
                    // staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.count = res.data.data.count
                    this.rank = res.data.data.rank
                    this.rankInfoList = res.data.data.rankInfoList
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },
    watch: {
        $route(to, from) {
            let que = to.query
            if (que && que.subordKey) {
                if (this.staffId != que.subordKey) {
                    this.staffId = que.subordKey
                }
                // 选定部门
                if (que.flag == 'isDept') {
                    this.daptDepartRankData()
                } else if (que.flag == 'isPers') { // 选定具体某人
                    this.getDepartRankData()
                }
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.DepartRank {
    .rankDescript {
        margin: 15px 15px 0;
        table {
            td {
                // border:1px solid red;
                color: #606266;
                vertical-align: middle;
                > div {
                    .font-size(22px);
                    color: #303133;
                }
            }
        }
    }
}
</style>
