<template>
    <div class="Itinerary moduleBox">
        <header>
            <!-- 日程 -->
            <!-- <span class="name">{{itemData.statName}}</span> -->
            <span class="name">{{$t('mx_mobile.workbench.1584933056797')}}</span>
        </header>

        <section class="workBody">
            <ul class="tabs van-hairline--bottom">
                <li v-for="(item,index) in tabs" :key="index" :class="[tabActive == index ? 'tabActive' : '']" @touchend.stop="tabActive=index">{{item}}</li>
            </ul>
            <div style="min-height:233px;">
                <current v-if="tabActive === 0" />
                <schedule ref="schedule" v-if="tabActive === 1" />
            </div>
        </section>
    </div>
</template>

<script>
import current from './current'
import schedule from './schedule'
export default {
    name: 'Itinerary',
    props: ['itemData'],
    data() {
        return {
            tabs: [
                this.$t('mx_mobile.workbench.1584932677885'), // '待处理'
                this.$t('mx_mobile.workbench.1584933056797') // '日程'
            ],
            tabActive: 0
        }
    },
    mounted() {
        // this.$refs.dialogAddMsg.open()
    },
    methods: {

    },
    components: {
        current, schedule
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
</style>
