<template>
    <div class="Remind moduleBox">
        <header>
            <!-- 提醒小秘书 -->
            <span class="name" v-if="flag == 'isPers'">{{itemData.statName}}</span>
            <!-- 部门提醒小秘书 -->
            <span class="name" v-else>{{$t('mx_mobile.common.1665642183639')}}</span>
        </header>
        <section class="workBody">
            <ul class="remindList">
                <li @click="toClient('BF001')">
                    <span>{{dataObj.custCount}}</span>
                    <!-- 您的客户总数 -->
                    <dt>{{$t('mx_mobile.workbench.1584966665256')}}</dt>
                    <!-- 比上月增加 -->
                    <!-- 个 -->
                    <dd v-if="dataObj.increaseCustCount > 0" v-html="$t('mx_mobile.workbench.1584966704023', [dataObj.increaseCustCount])">
                        <!-- {{$t('mx_mobile.common.1665643498466')}}
                        <em class="text-red">{{dataObj.increaseCustCount}}</em> {{$t('mx_mobile.Client.1626945752625')}} -->
                    </dd>
                    <!-- 比上月减少 -->
                    <!-- 个 -->
                    <dd v-else v-html="$t('mx_mobile.workbench.1584966727127', [dataObj.increaseCustCount])">
                        <!-- {{$t('mx_mobile.common.1665643624751')}}
                        <em class="text-red">{{dataObj.increaseCustCount}}</em> {{$t('mx_mobile.Client.1626945752625')}} -->
                    </dd>
                </li>
                <li>
                    <span>{{dataObj.thirtyCount}}</span>
                    <!-- 本月活跃客户 -->
                    <dt>{{$t('mx_mobile.workbench.1584966748262')}}</dt>
                    <!-- 比上月增加 -->
                    <!-- 个 -->
                    <dd v-if="dataObj.increaseThirtyCount > 0" v-html="$t('mx_mobile.workbench.1584966704023', [dataObj.increaseThirtyCount])">
                        <!-- {{$t('mx_mobile.common.1665643498466')}}
                        <em class="text-red">{{dataObj.increaseThirtyCount}}</em> {{$t('mx_mobile.Client.1626945752625')}} -->
                    </dd>
                    <!-- 比上月减少 -->
                    <!-- 个 -->
                    <dd v-else v-html="$t('mx_mobile.workbench.1584966727127', [dataObj.increaseThirtyCount])">
                        <!-- {{$t('mx_mobile.common.1665643624751')}}
                        <em class="text-red">{{dataObj.increaseThirtyCount}}</em> {{$t('mx_mobile.Client.1626945752625')}} -->
                    </dd>
                </li>
                <li @click="toClient('BF001',false,null,null,3)">
                    <span>{{dataObj.transTotal}}</span>
                    <!-- 成交客户总数 -->
                    <dt>{{$t('mx_mobile.workbench.1584966768281')}}</dt>
                    <!-- 比上月增加 -->
                    <!-- 个 -->
                    <dd v-if="dataObj.increaseTransCount > 0" v-html="$t('mx_mobile.workbench.1584966704023', [dataObj.increaseTransCount])">
                        <!-- {{$t('mx_mobile.common.1665643498466')}}
                        <em class="text-red">{{dataObj.increaseTransCount}}</em> {{$t('mx_mobile.Client.1626945752625')}} -->
                    </dd>
                    <!-- 比上月减少 -->
                    <!-- 个 -->
                    <dd v-else v-html="$t('mx_mobile.workbench.1584966727127', [dataObj.increaseTransCount])">
                        <!-- {{$t('mx_mobile.common.1665643624751')}}
                        <em class="text-red">{{dataObj.increaseTransCount}}</em> {{$t('mx_mobile.Client.1626945752625')}} -->
                    </dd>
                </li>
                <li @click="toClient('BF001',false,'2','a1')">
                    <span>{{dataObj.willInSeasCount}}</span>
                    <!-- 7天内将掉入公海 -->
                    <dt>{{$t('mx_mobile.workbench.1584966791563')}}</dt>
                </li>
                <li @click="toClient('BF001',false,'3','a2')">
                    <span>{{dataObj.fifteenNotCount}}</span>
                    <!-- 15天以上未跟进 -->
                    <dt>{{$t('mx_mobile.workbench.1584966812712')}}</dt>
                </li>
                <li @click="toClient('BF001',false,'3','a3')">
                    <span>{{dataObj.thirtyNotCount}}</span>
                    <!-- 30天以上未跟进 -->
                    <dt>{{$t('mx_mobile.workbench.1584966836761')}}</dt>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Remind',
    props: ['itemData', 'flag'],
    data() {
        return {
            dataObj: {},
            staffId: this.$router.currentRoute.query.subordKey || ''
        }
    },
    created() {
        if (this.flag == 'isPers') {
            this.getRemindData() // 下属
        } else {
            this.getDeptRemindData() // 部门
        }
    },
    mounted() { },
    computed: {
        ...mapGetters(['companie'])
    },
    methods: {
        toClient(modelCode, obj, timeType, interval, custState) { // 跳转客户
            let data = {
                modelCode: modelCode,
                interval: interval,
                argument: {}
            }
            console.log('data', data)

            if (obj) {
                data.argument = obj
            };
            if (timeType) {
                data.timeType = timeType
            }
            if (custState) {
                data.argument.custState = custState
            }
            // data.argument.ownerCtId = this.companie.ctId
            this.toClient_g(data)
        },
        getRemindData() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_pers_get, {
                params: {
                    statId: this.itemData.statId,
                    staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data && res.data.data.objectResults) {
                        this.dataObj = res.data.data.objectResults
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getDeptRemindData() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_dept_custSummarize_get, {
                params: {
                    statId: this.itemData.statId,
                    dkey: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data && res.data.data.objectResults) {
                        this.dataObj = res.data.data.objectResults
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },
    watch: {
        $route(to, from) {
            let que = to.query
            if (que && que.subordKey) {
                if (this.staffId != que.subordKey) {
                    this.staffId = que.subordKey
                }
                // 选定部门
                if (que.flag == 'isDept') {
                    this.getDeptRemindData()
                } else if (que.flag == 'isPers') { // 选定具体某人
                    this.getRemindData()
                }
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
</style>
