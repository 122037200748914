<template>
    <div class="MonthTrend moduleBox">
        <header>
            <!-- 月走势图 -->
            <span class="name">{{itemData.statName}}</span>

            <span class="btns" @click="dateOpen()">
                {{selectItem.name}}
                <i class="iconfont icon-page-down"></i>
            </span>
        </header>
        <section class="workBody">
            <ul class="tabs van-hairline--bottom van-tabs__nav">
                <li v-for="(item,index) in tabs" :key="index" :class="[tabActive == index ? 'tabActive' : '', 'tabLi']" @touchend.stop="tabActive=index; chartMonthTrend()">{{item}}</li>
            </ul>

            <div class="chartTitle">
                <span>
                    <!-- 指标 -->
                    <i class="iconfont icon-dot"></i>{{$t('mx_mobile.workbench.1584941301846')}}
                </span>
                <span>
                    <i class="iconfont icon-dot"></i>{{tabs[tabActive]}}</span>
            </div>

            <div style="min-height:188px;padding-bottom:5px;" ref="monthTrendObj"></div>
        </section>

    </div>
</template>

<script>
import echarts from 'echarts/lib/echarts' // 引入基本模板
import 'echarts/lib/chart/line' // 折线图
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'

export default {
    name: 'MonthTrend',
    props: ['itemData'],
    data() {
        return {
            // 一、时间区间下拉选择
            show: false,
            actions: [],
            selectItem: { // 默认时间段
                index: '6',
                name: this.$t('mx_mobile.workbench.1584941347942') // '最近6个月'
            },
            tabs: [
                this.$t('mx_mobile.workbench.1584939336363'), // '新增'
                this.$t('mx_mobile.workbench.1584939380583'), // '跟进客户'
                this.$t('mx_mobile.workbench.1584939397720'), // '跟进数'
                this.$t('mx_mobile.workbench.1584939411403'), // '成交'
                this.$t('mx_mobile.workbench.1584936207089') // '客户总数'
            ],
            tabActive: 0,

            monthTrendXArray: [],
            monthTrendYArray: [],
            targetXArray: [],
            targetYArray: [],
            myChart: null,
            staffId: this.$router.currentRoute.query.subordKey || '' // 接口需要的key
        }
    },
    created() {
        // 时间区间下拉
        if (this.itemData.statInterval) {
            this.actions = []
            this.itemData.statInterval.forEach(item => {
                this.actions.push({
                    index: item.intervalCode,
                    name: item.intervalName
                })
            })
        }
        this.chartMonthTrend()
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.monthTrendObj)
        window.onresize = () => {
            this.myChart.resize()
        }
    },
    methods: {
        // 时间区间选择
        async dateOpen() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.actions)
                if (!res) { return }
                this.selectItem = res
                this.chartMonthTrend() // 加载选择的数据
            } catch (e) {
                console.log(e)
            }
        },
        // 下属接口
        chartMonthTrend() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            this.isLoading = true
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_pers_get, {
                params: {
                    statId: this.itemData.statId,
                    interval: this.selectItem.index,
                    fieldId: this.tabActive + 1,
                    staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let resObject = res.data.data.objectResults
                    let resTarget = res.data.data.targetResults

                    this.monthTrendXArray = []
                    this.monthTrendYArray = []
                    this.targetXArray = []
                    this.targetYArray = []

                    if (resObject.length > 0) {
                        resObject.forEach(item => {
                            this.monthTrendXArray.push(item.month)
                            this.monthTrendYArray.push(item.count)
                        })
                    }
                    if (resTarget.length > 0) {
                        resTarget.forEach(item => {
                            this.targetXArray.push(item.month)
                            this.targetYArray.push(item.count)
                        })
                    }
                    this.getMyChart() // 加载图表传数据
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 图表
        getMyChart() {
            let option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['test'],
                    show: true,
                    right: '0',
                    itemWidth: 30,
                    itemHeight: '8'
                },
                textStyle: {
                    color: '#909399'
                },
                grid: {
                    left: '3%',
                    right: '8%',
                    bottom: '8%',
                    top: '8%',
                    containLabel: true
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            lineStyle: { color: '#909399' }
                        },
                        boundaryGap: false,
                        data: this.monthTrendXArray
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            lineStyle: { color: '#909399' }
                        },
                        // 网格
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#eee'
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: this.$t('mx_mobile.workbench.1584941301846'), // '指标'
                        type: 'line',
                        smooth: true,
                        symbol: 'roundRect',
                        stack: this.$t('mx_mobile.workbench.1584941566606'), // '总量'
                        itemStyle: {
                            normal: { color: '#ff851b' },
                            emphasis: { color: '#ff4141' }
                        },
                        data: this.targetYArray
                    },
                    {
                        name: this.tabs[this.tabActive],
                        type: 'line',
                        smooth: true,
                        symbol: 'roundRect',
                        stack: this.$t('mx_mobile.workbench.1584941566606'), // '总量'
                        itemStyle: {
                            normal: { color: '#ff4141' },
                            emphasis: { color: '#008cee' }
                        },
                        data: this.monthTrendYArray
                    }
                ]
            }
            this.myChart.setOption(option)
        }
    },
    watch: {
        $route(to, from) {
            let que = to.query
            if (que && que.subordKey) {
                if (this.staffId != que.subordKey) {
                    this.staffId = que.subordKey
                }
                // 选定部门
                if (que.flag == 'isDept') {
                    console.log('暂时没有部门接口')
                } else if (que.flag == 'isPers') { // 选定具体某人
                    this.chartMonthTrend()
                }
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.MonthTrend {
    .chartTitle {
        // border: 1px solid red;
        text-align: right;
        color: #606266;
        padding: 10px 15px 1px;
        > span {
            > i {
                font-size: 12px;
                margin: 1px 3px 0 8px;
            }
        }
        > span:nth-child(1) i {
            color: #ff851b;
        }
        > span:nth-child(2) i {
            color: #ff4141;
        }
    }
    .tabs {
        display: flex;
        .tabLi {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 3px;
        }
    }
}
</style>
