<template>
    <div class="WorkBenchHome">
        <van-button class="chooseAffi" plain round color="#fff" type="primary" @click="$router.push('/workbench/subordinate')">
            <span class="textSty">
                <!-- 选择下属/部门 -->
                {{subordName? subordName : $t('mx_mobile.workbench.1665632180216')}}<i class="iconfont icon-page-right"></i>
            </span>
        </van-button>
        <!-- 下属 -->
        <div class="Section">
            <template v-if="flag == 'isPers'">
                <section v-for="(item,index) in pannelArr" :key="index">
                    <component :is="item.name" :itemData="item" :flag='flag'></component>
                </section>
            </template>
            <!-- 部门 -->
            <section v-else v-for="(item,index) in departmentlArr" :key="index">
                <component :is="item.name" :itemData="item" :flag='flag'></component>
            </section>
        </div>
        <!-- <br>
      <itinerary />
      <breif-report />
      <remind />
      <customer-distribution />
      <activity />
      <depart-rank />
      <month-trend /> -->
    </div>
</template>

<script>
import Itinerary from './Itinerary'
import BreifReport from './BriefReport'
import Remind from './Remind'
import CustomerDistribution from './CustomerDistribution'
import Activity from './Activity'
import DepartRank from './DepartRank'
import MonthTrend from './MonthTrend'
import titleMixin from '../../../mixin/title'
import { mapActions } from 'vuex'
export default {
    name: 'WorkBench',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.workbench.1584601156817') }, // '工作台'
    data() {
        return {
            // 自定义模块匹配到返回数组
            pannelToArr: [
                {
                    statId: 1,
                    name: 'breif-report'
                },
                {
                    statId: 2,
                    name: 'itinerary'
                },
                {
                    statId: 3,
                    name: 'remind'
                },
                {
                    statId: 4,
                    name: 'customer-distribution'
                },
                {
                    statId: 5,
                    name: 'activity'
                },
                {
                    statId: 6,
                    name: 'depart-rank'
                },
                {
                    statId: 7,
                    name: 'month-trend'
                }
            ],
            departmentlToArr: [
                {
                    statId: 11,
                    name: 'remind'
                },
                {
                    statId: 12,
                    name: 'customer-distribution'
                },
                {
                    statId: 13,
                    name: 'activity'
                },
                {
                    statId: 10,
                    name: 'depart-rank'
                }
            ],
            pannelArr: [],
            departmentlArr: [],
            subordName: this.$router.currentRoute.query.subordName || '',
            subordKey: this.$router.currentRoute.query.subordKey || '', // 地址上的key
            flag: this.$router.currentRoute.query.flag || 'isPers'
        }
    },
    created() {
        if (this.flag == 'isPers') {
            this.getStatPanel()
        } else {
            this.getDepartmentl()
        }
    },
    mounted() {
        this.quickAdd() // 全局右上角快捷新增
        this.setIndividualConfigInfo()
    },
    methods: {
        // 个人信息存起来
        ...mapActions([
            'setIndividualConfigInfo'
        ]),
        // 接口获取工作台个人面板信息
        getStatPanel() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.subordKey = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.subordKey = route.query.subordKey
                }
            }
            this.axios
                .get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_panel_get, { params: { staffId: this.subordKey } })
                .then(
                    res => {
                        if (res.data.code.toString() == this.Global.config.RES_OK) {
                            if (res.data.data.length > 0) {
                                res.data.data.forEach(item => {
                                    this.pannelToArr.forEach(item2 => {
                                        if (item.statId == item2.statId) {
                                            item['name'] = item2.name // data 里匹配组件name
                                        }
                                    })
                                })
                            }
                            this.pannelArr = [].concat(
                                JSON.parse(JSON.stringify(res.data.data))
                            ) // 拷贝数组
                            // console.log('this.pannelArr', this.pannelArr)
                        } else {
                            this.$toast.fail(res.data.msg)
                        }
                    })
                .catch(error => {
                    console.log(error)
                })
        },
        // 部门面板初始化信息接口
        getDepartmentl() {
            this.axios
                .get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_dept_panel_get, { params: { dkey: this.subordKey } })
                .then(
                    res => {
                        if (res.data.code.toString() == this.Global.config.RES_OK) {
                            if (res.data.data.length > 0) {
                                res.data.data.forEach(item => {
                                    this.departmentlToArr.forEach(item2 => {
                                        if (item.statId == item2.statId) {
                                            item['name'] = item2.name // data 里匹配组件name
                                        }
                                    })
                                })
                            }
                            this.departmentlArr = [].concat(
                                JSON.parse(JSON.stringify(res.data.data))
                            ) // 拷贝数组
                            // console.log('this.departmentlArr', this.departmentlArr)
                        } else {
                            this.$toast.fail(res.data.msg)
                        }
                    })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    components: {
        itinerary: Itinerary,
        'breif-report': BreifReport,
        Remind,
        'customer-distribution': CustomerDistribution,
        Activity,
        'depart-rank': DepartRank,
        'month-trend': MonthTrend
    },
    watch: {
        $route(to, from) {
            let subordName = to.query.subordName
            let flag = to.query.flag
            if (to.query.subordKey) {
                this.subordKey = to.query.subordKey
            }
            if (flag) {
                this.flag = flag
                if (flag == 'isPers') {
                    this.getStatPanel()
                } else {
                    this.getDepartmentl()
                }
            }
            // let pannelArr = this.pannelArr
            if (subordName) {
                this.subordName = subordName
                // '我自己'
                if (this.subordName == this.$t('mx_mobile.workbench.1665633097451')) {
                    this.subordName = this.$t('mx_mobile.workbench.1665632180216') // 选择下属/部门
                }
            }
            let path = this.$route.path
            if (path === '/workbench' || path === '/workbench/') {
                // this.subordKey = this.companie.ctId
                // localStorage.setItem('subordKey', this.companie.ctId)
                this.quickAdd() // 全局右上角快捷新增
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" >
@import "./zh-cn.less";
.WorkBenchHome {
    user-select: none;
}
</style>
