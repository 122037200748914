<template>
    <div class="BriefReport moduleBox">
        <header>
            <!-- 简报 -->
            <span class="name">{{itemData.statName}}</span>
            <span class="btns" @click="dateOpen()">
                {{selectItem.name}}
                <i class="iconfont icon-page-down"></i>
            </span>
        </header>
        <section class="workBody">
            <div class="loadingWrap" v-if="isLoading">
                <loading />
            </div>
            <ul class="briefList">
                <li>
                    <div @click="toClient('BF0001')">{{addObj.newCount}}</div>
                    <!-- 新增客户数 -->
                    <span>{{$t('mx_mobile.workbench.1584938153473')}}</span>
                </li>
                <!-- <li>
                    <div>{{addObj.moveInCount}}</div>
                    <span>移交客户数</span>
                </li>
                <li>
                    <div @click="toClient('seas')">{{addObj.inSeaIntervalCount}}</div>
                    <span>掉入公海数</span>
                </li> -->
                <li>
                    <div>{{addObj.trackCount}}</div>
                    <!-- 新增跟进数 -->
                    <span>{{$t('mx_mobile.workbench.1584938208636')}}</span>
                </li>
                <li>
                    <div>{{addObj.trackCustCount}}</div>
                    <!-- 新增跟进客户数-->
                    <span>{{$t('mx_mobile.workbench.1584938245707')}}</span>
                </li>
                <li>
                    <div>{{addObj.mailCount}}</div>
                    <!-- 新增邮件数  -->
                    <span>{{$t('mx_mobile.workbench.1584938278357')}}</span>
                </li>
                <li v-for="(item,index) in trackArr" :key="index">
                    <div>{{item.count}}</div>
                    <span>{{item.itemName}}</span>
                </li>
            </ul>
        </section>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'BriefReport',
    props: ['itemData'],
    data() {
        return {
            // show: true,
            show: false,
            actions: [],
            selectItem: { // 默认时间段
                index: '3',
                name: this.$t('mx_mobile.workbench.1584939196431') // '本月'
            },
            isLoading: false,
            addObj: [],
            trackArr: [],
            // 接口需要的key
            staffId: this.$router.currentRoute.query.subordKey || ''
        }
    },
    created() {
        // 时间区间下拉
        if (this.itemData.statInterval) {
            this.actions = []
            this.itemData.statInterval.forEach(item => {
                this.actions.push({
                    index: item.intervalCode,
                    name: item.intervalName
                })
            })
        }
        this.getBriefReportData()
    },
    computed: {
        ...mapGetters(['companie'])
    },
    methods: {
        toClient(modelCode, obj, timeType, is) { // 跳转客户
            console.log('跳转客户', modelCode, obj, timeType)

            let data = {
                modelCode: modelCode,
                interval: this.selectItem.index,
                argument: {}
            }
            if (obj) {
                data.argument = obj
            };
            // 跳转客户中判断路由是否有部门下属的subordKey
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                data.argument.ownerCtId = this.companie.ctId

                if (JSON.stringify(route.query) != '{}') {
                    data.argument.ownerCtId = route.query.subordKey
                }
            }
            if (timeType) {
                data.timeType = timeType
            };
            this.toClient_g(data)
        },
        // 时间区间选择
        async dateOpen() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.actions)
                if (!res) { return }
                this.selectItem = res
                this.getBriefReportData() // 加载选择的数据
            } catch (e) {
                console.log(e)
            }
        },
        // 接口获取工作简报数据
        getBriefReportData() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            this.isLoading = true
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_pers_get, {
                params: {
                    statId: this.itemData.statId,
                    interval: this.selectItem.index,
                    staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isLoading = false
                    if (res.data.data && res.data.data.objectResults && res.data.data.objectResults.trackCount) {
                        this.addObj = res.data.data.objectResults.addCount
                        this.trackArr = res.data.data.objectResults.trackCount
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },
    watch: {
        $route(to, from) {
            let que = to.query
            if (que && que.subordKey) {
                if (this.staffId != que.subordKey) {
                    this.staffId = que.subordKey
                }
                // 选定部门
                if (que.flag == 'isDept') {
                    console.log('暂时没有简报部门接口')
                    // too
                } else if (que.flag == 'isPers') { // 选定具体某人
                    this.getBriefReportData()
                }
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.loadingWrap {
    // border:1px solid red;
    padding: 15px 0;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    text-align: center;
}
</style>
