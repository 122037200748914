<template>
    <div class="CustomerDistribution moduleBox">
        <header>
            <!-- 客户分布 -->
            <span class="name" v-if="flag == 'isPers'">{{itemData.statName}}</span>
            <!-- 部门客户分布 -->
            <span class="name" v-else>{{$t('mx_mobile.workbench.1665736484070')}}</span>

            <!-- 时间区间选择 -->
            <span class="btns" @click="dateOpen()">
                {{selectItem.name}}
                <i class="iconfont icon-page-down"></i>
            </span>
        </header>
        <section class="workBody">
            <ul class="tabs van-hairline--bottom">
                <li v-for="(item,index) in tabs" :key="index" :class="[tabActive == index ? 'tabActive' : '']" @touchend.stop="tabActive=index;" @click="handleFlagClick">{{item}}</li>
            </ul>

            <div class="chartsTitle">
                {{selectItem.name}}{{tabs[tabActive]}}{{$t('mx_mobile.workbench.1665736749982')}}<!-- 总数 -->
                <em class="text-red">{{totalNum}}</em>

                <!-- 字段下拉选择 -->
                <span class="pull-right" @click="fieldOpen()">
                    {{selectItem2.name}}
                    <i class="iconfont icon-page-down"></i>
                </span>
            </div>

            <div style="min-height:188px;position:relative;">
                <div style="height:188px;" ref="customerDistribObj"></div>
                <div class="otherState" v-if="chartArr.length === 0">
                    <nodata style="margin-top:-20px;" />
                </div>
                <div class="otherState" v-if="isLoading">
                    <loading style="margin-top:30px;" />
                </div>
            </div>

        </section>
    </div>
</template>

<script>
// 饼图
// 引入基本模板
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/pie' // 鼠标经过tips
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/tooltip'
export default {
    name: 'CustomerDistribution',
    props: ['itemData', 'flag'],
    data() {
        return {
            // 一、时间区间下拉选择
            show: false,
            actions: [],
            selectItem: { // 默认时间段
                index: '3',
                name: this.$t('mx_mobile.workbench.1584939196431') // '本月'
            },

            // 二、字段下拉选择
            // show2: true,
            show2: false,
            actions2: [],
            selectItem2: { // 默认时间段
                index: '2',
                name: this.$t('mx_mobile.workbench.1584941122428') // '规模'
            },

            // 三、客户状态TAB选择
            tabs: [
                this.$t('mx_mobile.workbench.1584939336363'), // '新增'
                this.$t('mx_mobile.workbench.1584941196656'), // '跟进'
                this.$t('mx_mobile.workbench.1584939411403'), // '成交'
                this.$t('mx_mobile.workbench.1584941210966') // '全部'
            ],
            tabActive: 0,
            totalNum: 0, // 总数计算
            chartArr: [],
            isLoading: false,
            myChart: null,

            // 接口需要的key
            staffId: this.$router.currentRoute.query.subordKey || ''

        }
    },
    created() {
        // 时间区间下拉
        if (this.itemData.statInterval) {
            this.actions = []
            this.itemData.statInterval.forEach(item => {
                this.actions.push({
                    index: item.intervalCode,
                    name: item.intervalName
                })
            })
        }
        // 字段下拉
        if (this.itemData.dicts) {
            this.actions2 = []
            this.itemData.dicts.forEach(item => {
                this.actions2.push({
                    index: item.dictCode,
                    name: item.dictName
                })
            })
        }
        if (this.flag == 'isPers') {
            this.chartCustomerDistrib() // 客户分布
        } else {
            this.getDeptDistribution()
        }
    },
    mounted() {
        this.myChart = echarts.init(this.$refs.customerDistribObj)
        window.onresize = () => {
            this.myChart.resize()
        }
    },
    methods: {
        handleFlagClick() {
            if (this.flag == 'isPers') {
                this.chartCustomerDistrib() // 客户分布
            } else {
                this.getDeptDistribution()
            }
        },
        // 时间区间选择
        async dateOpen() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.actions)
                if (!res) { return }
                this.selectItem = res
                if (this.flag == 'isPers') {
                    this.chartCustomerDistrib() // 客户分布
                } else {
                    this.getDeptDistribution()
                }
            } catch (e) {
                console.log(e)
            }
        },
        // 字段选择
        async fieldOpen() {
            try {
                let res = await this.Global.utils.actionSheet.open(this.actions2)
                if (!res) { return }
                this.selectItem2 = res
                if (this.flag == 'isPers') {
                    this.chartCustomerDistrib() // 客户分布
                } else {
                    this.getDeptDistribution()
                }
            } catch (e) {
                console.log(e)
            }
        },
        // 下属
        chartCustomerDistrib() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            this.isLoading = true
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_custDist_get, {
                params: {
                    interval: this.selectItem.index,
                    fieldId: this.tabActive + 1,
                    dictCode: this.selectItem2.index,
                    staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isLoading = false
                    this.chartArr = [] // 图表先清空
                    this.totalNum = 0 // 顺便计算总数

                    if (res.data.data && res.data.data.items) {
                        let dataArr = res.data.data.items
                        if (dataArr.length > 0) {
                            dataArr.forEach(item => {
                                if (item.count > 0) {
                                    this.chartArr.push({ value: item.count, name: item.itemName })
                                    this.totalNum += item.count // 顺便计算总数
                                }
                            })
                        }
                    }
                    this.getMyChart() // 加载图表传数据
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 部门
        getDeptDistribution() {
            this.isLoading = true
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_dept_custDist_get, {
                params: {
                    interval: this.selectItem.index,
                    fieldId: this.tabActive + 1,
                    dictCode: this.selectItem2.index,
                    dkey: this.staffId
                    // dkey: v
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isLoading = false
                    this.chartArr = [] // 图表先清空
                    this.totalNum = 0 // 顺便计算总数

                    if (res.data.data && res.data.data.items) {
                        let dataArr = res.data.data.items
                        if (dataArr.length > 0) {
                            dataArr.forEach(item => {
                                if (item.count > 0) {
                                    this.chartArr.push({ value: item.count, name: item.itemName })
                                    this.totalNum += item.count // 顺便计算总数
                                }
                            })
                        }
                    }
                    this.getMyChart() // 加载图表传数据
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 图表
        getMyChart() {
            let option = {
                title: {
                    show: false,
                    text: 'abc',
                    // 请求量
                    subtext: 'def',
                    x: 'left',
                    y: 'center',
                    itemGap: 0,
                    padding: [10, 0, 0, 40],
                    textStyle: {
                        fontSize: '28',
                        color: 'rgb(61, 66, 94)',
                        fontWeight: 400
                    },
                    subtextStyle: {
                        color: '#6b6b6b'
                    }
                },
                calculable: true,
                color: [
                    'RGBA(248, 135, 125, 1)',
                    'RGBA(139, 216, 103, 1)',
                    'RGBA(255, 183, 53, 1)'
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}({d}%)'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['51%', '40%'],
                        center: ['50%', '40%'],
                        hoverAnimation: false,
                        label: {
                            normal: {
                                // formatter: ['{title|{b}}', '{rateHead|{c}（{d}%）}'].join(
                                //   '\n'
                                // ),
                                rich: {
                                    title: {
                                        color: 'RGBA(96, 98, 102, 1)',
                                        fontSize: 14,
                                        align: 'left',
                                        padding: [3, 10, 3, 10]
                                    },
                                    abg: {
                                        width: 15,
                                        height: 20
                                    },
                                    rateHead: {
                                        color: 'RGBA(48, 49, 51, 1)',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        align: 'left',
                                        padding: [3, 10, 3, 10]
                                    }
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                label: {}
                            }
                        },
                        data: this.chartArr
                    }
                ]
            }
            this.myChart.setOption(option)
        }
    },
    watch: {
        $route(to, from) {
            let que = to.query
            if (que && que.subordKey) {
                if (this.staffId != que.subordKey) {
                    this.staffId = que.subordKey
                }
                // 选定部门
                if (que.flag == 'isDept') {
                    this.getDeptDistribution()
                } else if (que.flag == 'isPers') { // 选定具体某人
                    this.chartCustomerDistrib()
                }
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.chartsTitle {
    height: 42px;
    line-height: 45px;
    padding: 0 16px;
    // .font-size(12px);
    color: #606266;
    margin-right: 3px;
    .pull-right {
        .iconfont {
            color: #b6bac6;
            .font-size(12px);
        }
    }
}
.otherState {
    // border: 1px solid red;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
}
</style>
