<template>
    <div class="Activity moduleBox">
        <header>
            <!-- 活跃度 -->
            <span class="name" v-if="flag == 'isPers'">{{itemData.statName}}</span>
            <!-- 部门客户活跃度 -->
            <span class="name" v-else>{{$t('mx_mobile.workbench.1665737241363')}}</span>
        </header>
        <section class="workBody">
            <!-- 客户总数 -->
            <div class="bodyTitle">{{$t('mx_mobile.workbench.1584936207089')}}
                <em class="text-red">{{custCount}}</em>
            </div>
            <div class="table" style="margin-top:-5px;">
                <table class="widthFull" cellspacing="0">
                    <tbody>
                        <tr>
                            <td width="88" align="right">
                                <!-- 7天内有联系 -->
                                {{$t('mx_mobile.workbench.1584937768688')}}
                            </td>
                            <td>
                                <div class="progress">
                                    <span v-if="sevenCountPer==0" style="width:2%"></span>
                                    <span v-else :style="{width:parseFloat(sevenCountPer)+2+'%'}"></span>
                                </div>
                            </td>
                            <td width="38" align="right">
                                <span class="text-red">
                                    <strong>{{dataObj.sevenCount}}</strong>
                                </span>
                            </td>
                            <td width="38" align="right">{{sevenCountPer}}%</td>
                        </tr>
                        <tr>
                            <td align="right">
                                <!-- 15天内有联系 -->
                                {{$t('mx_mobile.workbench.1584937792877')}}
                            </td>
                            <td>
                                <div class="progress">
                                    <span v-if="fifteenCountPer==0" style="width:2%"></span>
                                    <span v-else :style="{width:parseFloat(fifteenCountPer)+2+'%'}"></span>
                                </div>
                            </td>
                            <td align="right">
                                <span class="text-red">
                                    <strong>{{dataObj.fifteenCount}}</strong>
                                </span>
                            </td>
                            <td align="right">{{fifteenCountPer}}%</td>
                        </tr>
                        <tr>
                            <td align="right">
                                <!-- 30天内有联系 -->
                                {{$t('mx_mobile.workbench.1584937810285')}}
                            </td>
                            <td>
                                <div class="progress">
                                    <span v-if="thirtyCountPer==0" style="width:2%"></span>
                                    <span v-else :style="{width:parseFloat(thirtyCountPer)+2+'%'}"></span>
                                </div>
                            </td>
                            <td align="right">
                                <span class="text-red">
                                    <strong>{{dataObj.thirtyCount}}</strong>
                                </span>
                            </td>
                            <td align="right">{{thirtyCountPer}}%</td>
                        </tr>
                        <tr>
                            <td align="right">
                                <!-- 60天内有联系 -->
                                {{$t('mx_mobile.workbench.1584937866747')}}
                            </td>
                            <td>
                                <div class="progress">
                                    <span v-if="sixtyCountPer==0" style="width:2%"></span>
                                    <span v-else :style="{width:parseFloat(sixtyCountPer)+2+'%'}"></span>
                                </div>
                            </td>
                            <td align="right">
                                <span class="text-red">
                                    <strong>{{dataObj.sixtyCount}}</strong>
                                </span>
                            </td>
                            <td align="right">{{sixtyCountPer}}%</td>
                        </tr>
                        <tr>
                            <td align="right">
                                <!-- 90天内有联系 -->
                                {{$t('mx_mobile.workbench.1584937881175')}}
                            </td>
                            <td>
                                <div class="progress">
                                    <span v-if="ninetyCountPer==0" style="width:2%"></span>
                                    <span v-else :style="{width:parseFloat(ninetyCountPer)+2+'%'}"></span>
                                </div>
                            </td>
                            <td align="right">
                                <span class="text-red">
                                    <strong>{{dataObj.ninetyCount}}</strong>
                                </span>
                            </td>
                            <td align="right">{{ninetyCountPer}}%</td>
                        </tr>
                        <tr>
                            <td align="right">
                                <!-- 从未有联系 -->
                                {{$t('mx_mobile.workbench.1584937920625')}}
                            </td>
                            <td>
                                <div class="progress">
                                    <span v-if="inactionCountPer==0" style="width:2%"></span>
                                    <span v-else :style="{width:parseFloat(inactionCountPer)+2+'%'}"></span>
                                </div>
                            </td>
                            <td align="right">
                                <span class="text-red">
                                    <strong>{{dataObj.inactionCount}}</strong>
                                </span>
                            </td>
                            <td align="right">{{inactionCountPer}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Activity',
    props: ['itemData', 'flag'],
    data() {
        return {
            dataObj: {},
            custCount: 0, // 总客户数
            fifteenCountPer: 0,
            inactionCountPer: 0,
            ninetyCountPer: 0,
            sevenCountPer: 0,
            sixtyCountPer: 0,
            thirtyCountPer: 0,
            startId: 5,
            staffId: this.$router.currentRoute.query.subordKey || ''
        }
    },
    created() {
        if (this.flag == 'isPers') {
            this.getActiveData()
        } else {
            this.getDeptActiveData()
        }
    },
    mounted() {

    },
    methods: {
        toDecimal(x) {
            var float = parseFloat(x)
            if (isNaN(float)) {
                return false
            }
            var f = Math.round(x * 10) / 10
            var s = f.toString()
            var rs = s.indexOf('.')
            if (rs < 0) {
                rs = s.length
                s += '.'
            }
            while (s.length <= rs + 1) {
                s += '0'
            }
            return s
        },
        // 下属接口
        getActiveData() {
            let route = this.$route
            if (route.path === '/workbench' || route.path === '/workbench/') {
                this.staffId = this.companie.ctId
                if (JSON.stringify(route.query) != '{}') {
                    this.staffId = route.query.subordKey
                }
            }
            this.isLoading = true
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_pers_get, {
                params: {
                    statId: this.itemData.statId,
                    // staffId: this.companie.ctId
                    staffId: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data && res.data.data.objectResults) {
                        // console.log(res.data)
                        let objectResults = res.data.data.objectResults
                        this.dataObj = objectResults

                        if (objectResults) {
                            if (objectResults.custCount) {
                                this.custCount = objectResults.custCount
                            }

                            if (objectResults.fifteenCount && objectResults.custCount != 0) {
                                this.fifteenCount = objectResults.fifteenCount
                                this.fifteenCountPer =
                                    this.toDecimal(parseFloat(this.fifteenCount / this.custCount) * 100)
                            }

                            if (objectResults.inactionCount && objectResults.custCount != 0) {
                                this.inactionCount = objectResults.inactionCount
                                this.inactionCountPer =
                                    this.toDecimal(parseFloat(this.inactionCount / this.custCount) * 100)
                            }

                            if (objectResults.ninetyCount && objectResults.custCount != 0) {
                                this.ninetyCount = objectResults.ninetyCount
                                this.ninetyCountPer =
                                    this.toDecimal(parseFloat(this.ninetyCount / this.custCount) * 100)
                            }

                            if (objectResults.sevenCount && objectResults.custCount != 0) {
                                this.sevenCount = objectResults.sevenCount
                                this.sevenCountPer =
                                    this.toDecimal(parseFloat(this.sevenCount / this.custCount) * 100)
                            }

                            if (objectResults.sixtyCount && objectResults.custCount != 0) {
                                this.sixtyCount = objectResults.sixtyCount
                                this.sixtyCountPer =
                                    this.toDecimal(parseFloat(this.sixtyCount / this.custCount) * 100)
                            }

                            if (objectResults.thirtyCount && objectResults.custCount != 0) {
                                this.thirtyCount = objectResults.thirtyCount
                                this.thirtyCountPer =
                                    this.toDecimal(parseFloat(this.thirtyCount / this.custCount) * 100)
                            }
                        }
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            })
                .catch(error => {
                    console.log(error)
                })
        },
        // 部门接口
        getDeptActiveData() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.stat_dept_activity_get, {
                params: {
                    statId: this.itemData.statId,
                    // staffId: this.companie.ctId
                    dkey: this.staffId
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data && res.data.data.objectResults) {
                        let objectResults = res.data.data.objectResults
                        this.dataObj = objectResults

                        if (objectResults) {
                            if (objectResults.custCount) {
                                this.custCount = objectResults.custCount
                            }

                            if (objectResults.fifteenCount && objectResults.custCount != 0) {
                                this.fifteenCount = objectResults.fifteenCount
                                this.fifteenCountPer =
                                    this.toDecimal(parseFloat(this.fifteenCount / this.custCount) * 100)
                            }

                            if (objectResults.inactionCount && objectResults.custCount != 0) {
                                this.inactionCount = objectResults.inactionCount
                                this.inactionCountPer =
                                    this.toDecimal(parseFloat(this.inactionCount / this.custCount) * 100)
                            }

                            if (objectResults.ninetyCount && objectResults.custCount != 0) {
                                this.ninetyCount = objectResults.ninetyCount
                                this.ninetyCountPer =
                                    this.toDecimal(parseFloat(this.ninetyCount / this.custCount) * 100)
                            }

                            if (objectResults.sevenCount && objectResults.custCount != 0) {
                                this.sevenCount = objectResults.sevenCount
                                this.sevenCountPer =
                                    this.toDecimal(parseFloat(this.sevenCount / this.custCount) * 100)
                            }

                            if (objectResults.sixtyCount && objectResults.custCount != 0) {
                                this.sixtyCount = objectResults.sixtyCount
                                this.sixtyCountPer =
                                    this.toDecimal(parseFloat(this.sixtyCount / this.custCount) * 100)
                            }

                            if (objectResults.thirtyCount && objectResults.custCount != 0) {
                                this.thirtyCount = objectResults.thirtyCount
                                this.thirtyCountPer =
                                    this.toDecimal(parseFloat(this.thirtyCount / this.custCount) * 100)
                            }
                        }
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    watch: {
        $route(to, from) {
            let que = to.query
            // console.log('to.query--CustomerDistribution', que)
            if (que && que.subordKey) {
                if (this.staffId != que.subordKey) {
                    this.staffId = que.subordKey
                }
                // 选定部门
                if (que.flag == 'isDept') {
                    this.getDeptActiveData()
                } else if (que.flag == 'isPers') { // 选定具体某人
                    this.getActiveData()
                }
            }
        }
    },
    components: {}
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.Activity {
    color: #606266;
    .bodyTitle {
        height: 45px;
        line-height: 45px;
        padding: 0 16px;
        .font-size(13px);
    }

    .progress {
        width: 100%;
        height: 6px;
        background: #efefef;
        border-radius: 8px;
        position: relative;
        top: 1px;
        overflow: hidden;
        > span {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(
                136.4deg,
                rgba(255, 122, 109, 1),
                rgba(255, 77, 62, 1)
            );
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }
    }
    .table {
        padding: 0 16px 12px 6px;
        td {
            // border:1px solid red;
            font-size: 12px;
            padding: 8px 3px;
        }
        tr:nth-child(2) .progress > span {
            background: linear-gradient(
                136.4deg,
                rgba(93, 219, 224, 1),
                rgba(0, 188, 218, 1)
            );
        }
        tr:nth-child(3) .progress > span {
            background: linear-gradient(
                136.4deg,
                rgba(59, 220, 72, 1),
                rgba(92, 209, 46, 1)
            );
        }
        tr:nth-child(4) .progress > span {
            background: linear-gradient(
                136.4deg,
                rgba(254, 190, 43, 1),
                rgba(255, 163, 0, 1)
            );
        }
        tr:nth-child(5) .progress > span {
            background: linear-gradient(
                136.4deg,
                rgba(86, 171, 254, 1),
                rgba(52, 138, 243, 1)
            );
        }
        tr:nth-child(6) .progress > span {
            background: linear-gradient(
                136.4deg,
                rgba(148, 117, 247, 1),
                rgba(119, 83, 233, 1)
            );
        }
    }
}
</style>
